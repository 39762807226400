import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/postLayout.js";
import Seo from "../../components/seo";
import VideoPlayer from "../../components/post/video";
import Info from "../../components/post/info";
import EmbedVideo from "../../components/post/embedvideo";
import Video0 from "../../images/work/atlasai/aperture/video0.mp4";
import Video1 from "../../images/work/atlasai/aperture/video1.mp4";
import Video2 from "../../images/work/atlasai/aperture/video2.mp4";
import Video3 from "../../images/work/atlasai/aperture/video3.mp4";
import Video4 from "../../images/work/atlasai/aperture/video4.mp4";
import Video5 from "../../images/work/atlasai/aperture/video5.mp4";
import Video6 from "../../images/work/atlasai/aperture/video6.mp4";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Seo title="Atlas AI - AR | Atharva Patil" mdxType="Seo" />
    <h1>{`Designing a geospatial tool to explore microscopic data in developing nations`}</h1>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1200px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "48.666666666666664%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Images of Atlas AI projects",
        "title": "Images of Atlas AI projects",
        "src": "/static/97f87bd09f1734db8649f0d88d365f19/c1b63/atlas-cover.png",
        "srcSet": ["/static/97f87bd09f1734db8649f0d88d365f19/5a46d/atlas-cover.png 300w", "/static/97f87bd09f1734db8649f0d88d365f19/0a47e/atlas-cover.png 600w", "/static/97f87bd09f1734db8649f0d88d365f19/c1b63/atlas-cover.png 1200w", "/static/97f87bd09f1734db8649f0d88d365f19/d61c2/atlas-cover.png 1800w", "/static/97f87bd09f1734db8649f0d88d365f19/efb68/atlas-cover.png 2032w"],
        "sizes": "(max-width: 1200px) 100vw, 1200px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <div style={{
      "maxWidth": "840px",
      "margin": "0 auto"
    }}>
      <h4>{`Overview`}</h4>
      <p>{`Aperture™ research is the first web-based product that lets customers explore `}<a parentName="p" {...{
          "href": "https://atlasai.co/?ref=patil"
        }}>{`Atlas AI’s`}</a>{` socioeconomic, demographic, infrastructure, and agricultural yield data across `}<b>{`Africa`}</b>{` and `}<b>{`South East Asia`}</b>{`. The web-based tool allows academic & institutional researchers to visualize & filter data to explore areas of interest.`}</p>
      <h4>{`My role`}</h4>
      <p>{`User & technology research, lead designer & prototyper `}<b>{`(2020 - Present)`}</b></p>
      <h4>{`Collaborators:`}</h4>
      <p>{`Deven Desai(Product), Jeff Bishop, Amit Sen, Jacob Chapman, Ginger Balmat(Engineering)`}</p>
      <h4>{`Context`}</h4>
      <p>{`Atlas AI uses ML & statistical models to generate data & insights at a microscopic level in developing nations where a lack of essential data can hinder decisions. Decisions like: `}<b>{`"Where do people need the most electrification infrastructure support?"`}</b>{` The task ahead was to make this cornucopia of data accessible to other researchers & institutions.`}</p>
      <p>{`With the data available from a country to `}<b>{`a continuous 2km x 2km grid`}</b>{` resolution on a continental scale, visualizing & presenting was a challenge. Geospatial tools have historically assumed expertise.`}</p>
      <Info header="Goal" body="Our goal was to make a simple platform accessible to researchers & organizations so that they could explore & understand on ground socioeconomic conditions." mdxType="Info"></Info>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "illustration highlighting the scale of microscopice data",
            "title": "illustration highlighting the scale of microscopice data",
            "src": "/static/16ea43b26e9debc0c0e446a40424ea4f/c1b63/magnify.png",
            "srcSet": ["/static/16ea43b26e9debc0c0e446a40424ea4f/5a46d/magnify.png 300w", "/static/16ea43b26e9debc0c0e446a40424ea4f/0a47e/magnify.png 600w", "/static/16ea43b26e9debc0c0e446a40424ea4f/c1b63/magnify.png 1200w", "/static/16ea43b26e9debc0c0e446a40424ea4f/0a013/magnify.png 1703w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`The different resolutions of data.`}</figcaption>
      </figure>
      <EmbedVideo src={Video0} caption={"Interaction highlight: Microscopic nature of data variation"} mdxType="EmbedVideo" />
      <hr></hr>
      <h4>{`1. Understanding`}</h4>
      <h3>{`Visualizing: Data`}</h3>
      <p>{`Our first goal was to present the data so a user could understand “How a variable varied across a geographical area.” Since our data had no geographical gaps, choropleths were a natural answer to represent most of our data.`}</p>
      <p>{`User research highlighted key insights on traditional geospatial products:`}</p>
      <ul>
        <li parentName="ul">
          <b>Layering</b>: Data layers are overlaid on top of each other in varying
z-indices with options to turn them on/off & change the opacity. Making
multiple layers visible with varying opacities was detrimental to
understanding data.
        </li>
        <li parentName="ul">
          <b>Adding data</b>: To avoid loading massive datasets, users need to select a
dataset to visualize by searching through a massive catalog which was daunting
for them as the initial action.
        </li>
      </ul>
      <p>{`We chose to simplify this by limiting their options to visualize only a single layer at a time(radio over checkbox). In addition, the dataset names show up as layers that are fetched on selection, reducing the initial step.`}</p>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Product screenshot highlighting sub country spending data",
            "title": "Product screenshot highlighting sub country spending data",
            "src": "/static/77642f078669a627c185e22cdb10558b/c1b63/image1.png",
            "srcSet": ["/static/77642f078669a627c185e22cdb10558b/5a46d/image1.png 300w", "/static/77642f078669a627c185e22cdb10558b/0a47e/image1.png 600w", "/static/77642f078669a627c185e22cdb10558b/c1b63/image1.png 1200w", "/static/77642f078669a627c185e22cdb10558b/0a013/image1.png 1703w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`
    Product screenshot highlighting sub country spending data.
  `}</figcaption>
      </figure>
      <EmbedVideo src={Video1} caption={"Interaction highlight: Selecting different visualizations"} mdxType="EmbedVideo" />
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Key region highlights for a data attribute",
            "title": "Key region highlights for a data attribute",
            "src": "/static/490f9156d1ec7313f900cf0f336ca7c9/c1b63/image2.png",
            "srcSet": ["/static/490f9156d1ec7313f900cf0f336ca7c9/5a46d/image2.png 300w", "/static/490f9156d1ec7313f900cf0f336ca7c9/0a47e/image2.png 600w", "/static/490f9156d1ec7313f900cf0f336ca7c9/c1b63/image2.png 1200w", "/static/490f9156d1ec7313f900cf0f336ca7c9/0a013/image2.png 1703w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Key region highlights for a data attribute.`}</figcaption>
      </figure>
      <h3>{`Visualizing: Time & Space`}</h3>
      <p>{`The second goal was to help users explore the data historically & across spatial resolution ( Country, State, District, County, etc.). Helping users understand how a certain variable changed over time in a certain place( eg: How much has average spending grown in the last 5 years).`}</p>
      <p>{`In initial explorations, the idea was to tie these controls with the visualization layer name card or make them global selectors. Those iterations had one major shortcoming if either of the controls changed. The legend is updated with a different scale of data. At this point, `}<b>{`the visual variation of color in choropleth would not convey the appropriate scale`}</b>{`. So, the Time & Space controls went alongside the legend.`}</p>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Visualizing temporal data for a region",
            "title": "Visualizing temporal data for a region",
            "src": "/static/663ba1d717fef2010e71655349da77d6/c1b63/image3.png",
            "srcSet": ["/static/663ba1d717fef2010e71655349da77d6/5a46d/image3.png 300w", "/static/663ba1d717fef2010e71655349da77d6/0a47e/image3.png 600w", "/static/663ba1d717fef2010e71655349da77d6/c1b63/image3.png 1200w", "/static/663ba1d717fef2010e71655349da77d6/0a013/image3.png 1703w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Visualizing temporal data for a region.`}</figcaption>
      </figure>
      <EmbedVideo src={Video2} caption={"Interaction highlight: Visualing data over space and time."} mdxType="EmbedVideo" />
      <h4>{`2. Analysing`}</h4>
      <h3>{`Filtering`}</h3>
      <p>{`To take meaningful action on geography usually isn’t dependent on a single variable. A combination of variables helps identify target areas that fit a scenario. For eg, If an organization wanted to identify key areas where they could install solar panels for a new electrification project, they might ask, “Where can I find areas with a large population where the spending is at least $1 a day and hasn’t been electrified.”`}</p>
      <p>{`One of our choices was to keep the visualization a neutral blue color when applying filters. We chose to forgo any color scales when visualizing filters because it helped keep the action distinct from a passive visualization. Secondly, it helped clearly distinguish between filtered areas & selected areas(next section).`}</p>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Multiple data filters applied over a region",
            "title": "Multiple data filters applied over a region",
            "src": "/static/a4a74e1caf1fae122b0fdb66e3099b0a/c1b63/image4.png",
            "srcSet": ["/static/a4a74e1caf1fae122b0fdb66e3099b0a/5a46d/image4.png 300w", "/static/a4a74e1caf1fae122b0fdb66e3099b0a/0a47e/image4.png 600w", "/static/a4a74e1caf1fae122b0fdb66e3099b0a/c1b63/image4.png 1200w", "/static/a4a74e1caf1fae122b0fdb66e3099b0a/0a013/image4.png 1703w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Multiple data filters applied over a region.`}</figcaption>
      </figure>
      <EmbedVideo src={Video3} caption={"Interaction highlight: Adding filters to narrow down areas of interest."} mdxType="EmbedVideo" />
      <h3>{`Selection & export`}</h3>
      <p>{`Even with a diverse breadth of data, not all questions can be answered by simply looking at a map or a data table. The end users were academics and researchers who wanted to use our data as a kicking-off point to further work on a problem by combining their data.`}</p>
      <p>{`They can choose to export information in one of two ways.`}</p>
      <ul>
        <li parentName="ul">
          <b>Area selection</b>: Exporting a continental scale dataset seems
unproductive when interested in a small section of a country. Thus an area
selector was introduced to help select the specific regions to export.
        </li>
        <li parentName="ul">
          <b>Custom exporter</b>: While the area selector helped focus on the current
data, the custom exporter can help quickly select datasets at different time
and space vintages which are helpful for historical analysis.
        </li>
      </ul>
      <EmbedVideo src={Video4} caption={"Interaction highlight: Selecting areas of interest for export."} mdxType="EmbedVideo" />
      <EmbedVideo src={Video5} caption={"Interaction highlight: Selecting areas of interest for export."} mdxType="EmbedVideo" />
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Final state of custom data prep before export",
            "title": "Final state of custom data prep before export",
            "src": "/static/12d262469a6a45e1fc03f8e654bb1f3c/c1b63/image5.png",
            "srcSet": ["/static/12d262469a6a45e1fc03f8e654bb1f3c/5a46d/image5.png 300w", "/static/12d262469a6a45e1fc03f8e654bb1f3c/0a47e/image5.png 600w", "/static/12d262469a6a45e1fc03f8e654bb1f3c/c1b63/image5.png 1200w", "/static/12d262469a6a45e1fc03f8e654bb1f3c/0a013/image5.png 1703w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Final state of custom data prep before export.`}</figcaption>
      </figure>
      <Info header="Results" body="Multiple organizations have used the platform to explore data with a subscription based model. Over time it has been a successful internal sales tool to garner interest in the underlying data, fostering multiple partnerships." mdxType="Info"></Info>
      <p>{`Some case studies using the platform:`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.atlasai.co/insights/india-top-trends-a-tale-of-five-cities"
          }}>{`India: A Tale of Five Cities`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.atlasai.co/insights/measuring-economic-growth"
          }}>{`Measuring Economic Growth From Space`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.atlasai.co/insights/expanding-energy-access"
          }}>{`Expanding Energy Access`}</a></li>
      </ul>
      <hr></hr>
      <h4>{`Other highlights`}</h4>
      <h3>{`Covid vaccine distribution storymap (frontend dev)`}</h3>
      <p>{`I worked on a scroll-based map interactive `}<i>{`(think NYT)`}</i>{` case study to highlight further research into the potential distribution of Covid-19 vaccines across the African continent. I worked on data wrangling, design, and front-end development. Featured by Mapbox across multiple platforms.`}</p>
      <p><a parentName="p" {...{
          "href": "https://twitter.com/_atharvapatil/status/1367682395437228033"
        }}>{`Check out a quick preview here`}</a></p>
      <h3>{`Company website`}</h3>
      <p>{`I have been designing and maintaining the `}<a parentName="p" {...{
          "href": "https://www.atlasai.co/?ref=patil"
        }}>{`company website`}</a>{`, created using Webflow alongside the `}<a parentName="p" {...{
          "href": "https://docs.atlasai.co/?ref=patil"
        }}>{`data documentation`}</a>{` powered by Mk-docs and have been serving as a content and styling steward for the latter in collaboration with Vivek Sakrani, Head of Applied data science`}</p>
      <h3>{`Fly-to tool`}</h3>
      <p>{`I created a simple internal tool where I did design and front-end dev to help create fly-to videos highlighting our data using the Mapbox API. Here’s a sample of how the result ends up.`}</p>
      <EmbedVideo src={Video6} caption={"Visualizing different scales of AWI data in a snapshot."} mdxType="EmbedVideo" />
      <hr></hr>
      <h2>{`More info, questions?`}</h2>
      <p>{`This was the first product we built. There have been multiple prototypes that went in different directions.`}</p>
      <p>{`Since then, we have been working on our second product using the learnings of the first one. A `}<a parentName="p" {...{
          "href": "/work/demand-intel"
        }}>{`demand intelligence platform`}</a>{` that arms our customers with a deeper understanding of their data and actionable insights to grow their business.`}</p>
      <p>{`If you any any questions or wanna know more, feel free to `}<a parentName="p" {...{
          "href": "mailto:atharvaabhaypatil@gmail.com"
        }}>{`reach out`}</a>{`.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      